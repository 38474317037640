<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron sistemas"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :toolbar-options="toolbarOptions"
      :page-loaded="pageLoaded"
      pk-name="idsistema"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <sistema-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <b10-map-markers
      :markers="mapMarkers"
      title="Mapa sistemas"
      :showing.sync="showingDialogs.map"
      icon-fieldname="icon"
      @bounds-changed="mapBoundsChanged"
      @click-marker="clickMapMarker"
    />
    <b10-map-marker-info
      :title="mapMarkerInfo.title"
      :subtitle="mapMarkerInfo.subtitle"
      :lat="mapMarkerInfo.lat"
      :lng="mapMarkerInfo.lng"
      :showing.sync="showingDialogs.mapMarkerInfo"
    >
      <template slot="cardExtraButtons">
        <v-btn
          color="info"
          dark
          @click="clickVerSistema(mapMarkerInfo.idsistema)"
        >
          <v-icon left>
            {{ $vuetify.icons.values.sistemas }}
          </v-icon> Ver sistema
        </v-btn>
      </template>
    </b10-map-marker-info>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './SistemaListData'
import SistemaListItem from './components/SistemaListItem'
import { get } from 'vuex-pathify'
import _ from '@/utils/lodash'
import { markerIcons } from '@/utils/maps'
import { NextRouteNavigate } from '@/router'

const pageStoreName = 'pagesSistemaList'

export default {
  components: {
    SistemaListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
      },
      showingDialogs: {
        mapMarkerInfo: false,
        map: false,
      },
      mapMarkerInfo: {
        title: '',
        subtitle: '',
        lat: null,
        lng: null,
      },
      mapMarkers: [],
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    nextRoute: get(`${pageStoreName}/nextRoute`),
  },
  async created () {
    // routeQuery.idcliente: filtro por cliente
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Sistemas'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectSistema(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeQuery.idcliente
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectSistemaRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idsistema')
      } finally {
        this.loadingData = false
      }
    },
    async clickListItem (item) {
      if (this.nextRoute) {
        if (_.has(this.nextRoute.query, 'idsistema')) {
          await this.setStoreProperty('nextRoute@query.idsistema', item.data.idsistema)
          NextRouteNavigate(this.nextRoute)
        }
      } else {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'sistemas__sistema-view',
          params: {
            idsistema: item.data.idsistema
          }
        })
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        this.showingDialogs.map = true
      }
    },
    clickMapMarker (markerData) {
      this.mapMarkerInfo.title = this.$online.sistema.title(markerData)
      this.mapMarkerInfo.subtitle = this.$online.sistema.subtitle(markerData)
      this.mapMarkerInfo.lat = markerData.latitud
      this.mapMarkerInfo.lng = markerData.longitud
      this.mapMarkerInfo.idsistema = markerData.idsistema
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
    },
    async mapBoundsChanged (bounds) {
      let [markers] = await Data.selectSistema(
        this,
        this.filter,
        this.search,
        this.sorter,
        0,
        this.routeQuery.idcliente,
        bounds,
        0
      )
      markers = _.map(markers, (marker) => {
        marker.icon = markerIcons.sistema
        return marker
      })
      this.mapMarkers = markers
    },
    clickIrASistema(idsistema) {
      this.showingDialogs.map = !this.showingDialogs.map
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'sistemas__sistema-view',
        params: {
          idsistema: idsistema
        }
      })
    }
  },
}
</script>
