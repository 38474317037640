import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectSistema (Vue, filter, search, sorter, page, idcliente, mapBounds, pageSize) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
    if (idcliente) {
      apiFilter.addExact('idcliente', idcliente)
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.sistema.searchFilter(search))
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    if (filter.estado.value) {
      apiFilter.addIn(filter.estado.field, filter.estado.value)
    }
    if (mapBounds) {
      apiFilter.addGT('latitud', mapBounds.swLat)
      apiFilter.addLT('latitud', mapBounds.neLat)
      apiFilter.addGT('longitud', mapBounds.swLng)
      apiFilter.addLT('longitud', mapBounds.neLng)
    }
    const resp = await Vue.$api.call('sistema.selectList', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSistemaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idsistema', pks)
    const resp = await Vue.$api.call('sistema.selectList', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
