<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
        <extra-subtitle
          :estado-cliente="formattedItem.estadoCliente"
          :estado-sistema="formattedItem.estadoSistema"
        />
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { SISTEMA } from '@/utils/consts'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'
import ExtraSubtitle from '../../../components/SistemaExtraSubtitle'

export default {
  components: {
    ExtraSubtitle,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      SISTEMA
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$online.sistema.title(item)
      item.subtitle = this.$online.sistema.subtitle(item)
      item.avatar = this.$options.filters.initials(item.descripcion)
      item.avatarColor = stringToHslColor(calculateMd5(item.idsistema.toString()))
      item.estadoCliente = item.estado_cliente
      item.estadoSistema = item.estado
      return item
    }
  }
}
</script>
